const FONT_CONFIGS = [
  {
    weight: 200,
    varName: "--font-extra-light",
    name: "Extra Light",
  },
  {
    weight: 300,
    varName: "--font-light",
    name: "Light",
  },
  {
    weight: 400,
    varName: "--font-regular",
    name: "Regular",
  },
  {
    weight: 500,
    varName: "--font-medium",
    name: "Medium",
  },
  {
    weight: 600,
    varName: "--font-semi-bold",
    name: "Semi Bold",
  },
  {
    weight: 700,
    varName: "--font-bold",
    name: "Bold",
  },
];

const DEFAULT_ROOT_FONT_SIZE = 10;

// generate fontface
const createFontFaceSet = (fontFamily, fontUrl, options) =>
  new FontFace(fontFamily, `url(${fontUrl})`, options);

// loads fontface
const loadFont = async (fontFace) => await fontFace.load();

// adds font to document and updates root style var
const addFontToDocument = (fontFace, varName, fontName) => {
  const root = document.documentElement;
  document.fonts.add(fontFace);
  root.style.setProperty(varName, fontName);
};

export const generateFontFaceAndLoad = async (fontFamily, fontUrl) => {
  for (let idx = 0; idx < FONT_CONFIGS.length; idx++) {
    const fontConfig = FONT_CONFIGS[idx];
    const { varName, name } = fontConfig;
    const fontName = `${fontFamily} ${name}`;
    const fontFace = createFontFaceSet(fontName, fontUrl, {
      weight: fontConfig.weight,
      style: "normal",
    });
    try {
      // we only need to load the font once
      if (idx === 0) await loadFont(fontFace);
      addFontToDocument(fontFace, varName, fontName);
    } catch (err) {
      console.log("Failed to load fontFace", err);
      break;
    }
  }
};

export const updateRootFontSize = (fontSizeScaling) => {
  const resultantFontSize = DEFAULT_ROOT_FONT_SIZE * fontSizeScaling;
  const root = document.documentElement;
  root.style.setProperty("--root-font-size", `${resultantFontSize}px`);
};
