"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.saveUserApprovalToStorage = exports.isValidSession = exports.usersMatch = exports.getUserFromSession = exports.getCurrentSession = exports.getParentWebsiteOrigin = exports.MISSING_REQUESTER_DOMAIN = exports.DOMAIN_APPROVAL_TTL_MILLIS = void 0;
const constants_1 = require("../constants");
const store_1 = require("../store");
const logger_1 = require("frontend-utils/logger");
const storageHelper_1 = require("./storageHelper");
const frontend_utils_1 = require("frontend-utils");
const { SESSION_KEY_TYPES } = frontend_utils_1.SessionKeysUtils;
exports.DOMAIN_APPROVAL_TTL_MILLIS = 60 * 60 * 24 * 30 * 1000; //30 days
exports.MISSING_REQUESTER_DOMAIN = "MISSING_REQUESTER_DOMAIN";
const userApprovalStorage = new storageHelper_1.UserApprovalStorage();
// get domains of parent window
// check this
// https://stackoverflow.com/questions/3420004/access-parent-url-from-iframe/69049876#69049876
const getParentWebsiteOrigin = () => {
    let origin = exports.MISSING_REQUESTER_DOMAIN;
    try {
        // First, check if the document is defined and has a referrer property.
        if (document && document.referrer) {
            // Attempt to create a URL object from the referrer
            origin = new URL(document.referrer).origin;
        }
        else if (window.location &&
            window.location.ancestorOrigins &&
            window.location.ancestorOrigins[0]) {
            // Check if ancestorOrigins is defined and has an origin at index 0
            origin = window.location.ancestorOrigins[0];
        }
    }
    catch (e) {
        // Handle unexpected exceptions for both conditions
        console.error("Origin get error:", e);
    }
    return origin || exports.MISSING_REQUESTER_DOMAIN;
};
exports.getParentWebsiteOrigin = getParentWebsiteOrigin;
const getUserJWTPayload = async () => {
    var _a, _b;
    const currentSession = await (0, exports.getCurrentSession)();
    return (_b = (_a = currentSession === null || currentSession === void 0 ? void 0 : currentSession.getIdToken) === null || _a === void 0 ? void 0 : _a.call(currentSession)) === null || _b === void 0 ? void 0 : _b.payload;
};
// aws get current session
const getCurrentSession = async () => {
    const currentSession = await frontend_utils_1.MetakeepAuth.currentSession();
    return currentSession;
};
exports.getCurrentSession = getCurrentSession;
// get parsed user from session
const getUserFromSession = async () => {
    return await getUserJWTPayload().then((attr) => {
        if (!(attr === null || attr === void 0 ? void 0 : attr.user)) {
            throw new Error("User not found in session.");
        }
        return new frontend_utils_1.ParsedUser(attr === null || attr === void 0 ? void 0 : attr.user);
    });
};
exports.getUserFromSession = getUserFromSession;
const usersMatch = (userFromSession, userFromRequest) => userFromSession.equals(userFromRequest);
exports.usersMatch = usersMatch;
// check if session is valid
// matches user from session with user from request
// checks if current domain and APP ID matches with domain and APP ID from local storage
const isValidSession = async (user, currentDomain, appId) => {
    var _a, _b;
    logger_1.logger.log("isValidSession: running ", user, currentDomain, appId);
    // Retrieve the flag indicating whether passkey is being used
    const usePasskey = store_1.useStore.getState().usePasskey;
    frontend_utils_1.MetakeepAuth.setCurrentAuthenticatedUser(user);
    try {
        // Retrieve the user associated with the current session
        const userFromSession = await (0, exports.getUserFromSession)();
        // Get the session ID from the current session
        const sessionId = (_b = (_a = (await frontend_utils_1.MetakeepAuth.currentSession())) === null || _a === void 0 ? void 0 : _a.getIdToken()) === null || _b === void 0 ? void 0 : _b.payload.origin_jti;
        // Parameters for fetching keys from the database
        const fetchKeysParams = {
            keyPrefix: constants_1.METAKEEP_IDENTITY_PROVIDER_PREFIX,
            parsedUser: userFromSession,
            sessionId: sessionId,
        };
        // Fetch crypto key from the database
        const cryptoKey = (await frontend_utils_1.SessionKeysUtils.fetchKeysFromDb(Object.assign(Object.assign({}, fetchKeysParams), { keyType: SESSION_KEY_TYPES.CRYPTO })));
        // Crypto key is required for the session to be valid
        // Check if a public key exists in crypto key, if not, session is invalid
        if (!(cryptoKey === null || cryptoKey === void 0 ? void 0 : cryptoKey.publicKey)) {
            logger_1.logger.debug("isValidSession: Crypto key public key not found, session is invalid");
            return false;
        }
        // Attempt to fetch WebAuthn keys from the database
        const webauthnKey = (await frontend_utils_1.SessionKeysUtils.fetchKeysFromDb(Object.assign(Object.assign({}, fetchKeysParams), { keyType: SESSION_KEY_TYPES.WEBAUTHN })));
        // If webauthn key exists, passkey operations should be available.
        if (webauthnKey) {
            // Passkey is disabled, session is invalid
            if (!(constants_1.USE_PASSKEY || usePasskey)) {
                logger_1.logger.debug("isValidSession: Passkey is disabled but WebAuthn key exists, session is invalid");
                return false;
            }
            const isPlatformAuthenticatorAvailable = await frontend_utils_1.PasskeyUtils.isWebAuthnAuthenticatorAvailable();
            logger_1.logger.log({ isPlatformAuthenticatorAvailable });
            // If credential ID is missing or WebAuthn authenticator is not available, session is invalid
            if (!webauthnKey.credentialId || !isPlatformAuthenticatorAvailable) {
                logger_1.logger.debug("isValidSession: Credential ID is missing or WebAuthn authenticator is not available, session is invalid");
                return false;
            }
        }
        // Check if the users match
        if (!(0, exports.usersMatch)(userFromSession, user)) {
            logger_1.logger.debug("isValidSession: Users do not match, session is invalid");
            return false;
        }
        // Check if user is approved for the current domain and app ID
        if (!(await isUserApproved(currentDomain, appId))) {
            logger_1.logger.debug("isValidSession: User is not approved for the current domain and app ID, session is invalid");
            return false;
        }
        return true;
    }
    catch (err) {
        logger_1.logger.error(err);
        return false;
    }
};
exports.isValidSession = isValidSession;
//set approval in user approval storage
const saveUserApprovalToStorage = async (domain, appId) => {
    const parsedUser = await (0, exports.getUserFromSession)();
    userApprovalStorage.saveToUserApprovalStorage(domain, parsedUser, appId);
};
exports.saveUserApprovalToStorage = saveUserApprovalToStorage;
//check if domain is valid
const isUserApproved = async (currentDomain, appId) => {
    const parsedUser = await (0, exports.getUserFromSession)();
    const userApprovalsFrmLS = userApprovalStorage.getUserApprovalsForUser(parsedUser, appId);
    return userApprovalsFrmLS.findIndex((d) => d.domain === currentDomain) > -1;
};
