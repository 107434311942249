"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.requestSlice = void 0;
const constants_1 = require("../constants");
const utils_1 = require("../utils");
const requestSlice = (set, get) => ({
    requestId: null,
    client: null,
    payload: null,
    isMobileApp: false,
    setupRequestB64Slice: (options) => {
        const { requestId = null, client = null, payload = null } = options;
        const isMobileApp = client &&
            (client.type === constants_1.IOS_CLIENT_TYPE || client.type === constants_1.ANDROID_CLIENT_TYPE);
        const { setRequesterDomain } = get();
        const requesterDomain = (0, utils_1.getParentWebsiteOrigin)();
        // Requester domain is set when the frame starts: https://github.com/PassbirdCo/MetaKeep/blob/main/fe/react-consent-iframe/src/App.js#L46
        // Since there is no parent website on mobile, we set the package name as the requester domain.
        if (isMobileApp && requesterDomain === utils_1.MISSING_REQUESTER_DOMAIN)
            setRequesterDomain(client.package || utils_1.MISSING_REQUESTER_DOMAIN);
        set(() => ({
            requestId,
            client,
            payload,
            isMobileApp: Boolean(isMobileApp),
        }));
    },
});
exports.requestSlice = requestSlice;
