import { lazy, Suspense, useCallback, useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Base64 } from "js-base64";
import { FrameLayout } from "./layout";
import { useStore } from "./store";
import { getParentWebsiteOrigin } from "./utils";
import PrefetchFrame from "./containers/PrefetchFrame";
import { Widget } from "./containers/Widget";
import { shallow } from "zustand/shallow";
import React from "react";
import { VisibilityTracker } from "./utils/visibiltyTracker";

const Login = lazy(() => import("./containers/Login"));
const ConsentFrame = lazy(() => import("./containers/ConsentFrame"));
const ApprovalFrame = lazy(() => import("./containers/ApprovalFrame"));
const NotFound = lazy(() => import("./containers/NotFound"));

// The app can be called by the SDK in three possible ways
// 1. Legacy SDK calls the app with direct route for eg /sign, /login etc
//    in this case individual routes handle the payload sent by the SDK using message event
// 2. Newer versions of SDK and Mobile SDK calls the app using routes with requestB64 params eg /login?requestB64=xxxx
//    in this App.js parses the requestB64 param and sets the global state using setupB64RequestState action
// 3. Latest version of SDK on web will open a /widget route and pass the request payload via message event
//    in this case the widget route will handle the payload sent and set the global state using initializeFrame action

function App() {
  const { setRequesterDomain, setupB64RequestState } = useStore(
    (state) => ({
      setRequesterDomain: state.setRequesterDomain,
      setupB64RequestState: state.setupB64RequestState,
    }),
    shallow
  );
  const [isMounted, setMounted] = useState(false);
  const { search, pathname } = useLocation();
  const params = new URLSearchParams(search);
  const requestB64 = params.get("requestB64");

  // This is called when SDK calls the client with a requestB64 param
  const readRequestB64Params = useCallback(() => {
    if (!requestB64) return;
    const request = JSON.parse(Base64.decode(requestB64));
    setupB64RequestState(request, pathname);
  }, [pathname, requestB64, setupB64RequestState]);

  useEffect(() => {
    const requesterDomain = getParentWebsiteOrigin();
    setRequesterDomain(requesterDomain);
    readRequestB64Params();
    setMounted(true);
    return () => {};
  }, [readRequestB64Params, setRequesterDomain]);

  // This gives us time for all the App level effects to complete
  // hence when the actual app renders after all top level states are set
  if (!isMounted) return null;
  return (
    <Suspense fallback={<></>}>
      <FrameLayout render={renderRoutes} />
    </Suspense>
  );
}

const renderRoutes = (injectedProps: {
  visibilityTracker: VisibilityTracker | null;
}) => {
  return (
    <Routes>
      <Route path="/" element={<ConsentFrame {...injectedProps} />} />
      <Route path="/sign" element={<ApprovalFrame {...injectedProps} />} />
      <Route path="/login" element={<Login {...injectedProps} />} />
      <Route path="/prefetch" element={<PrefetchFrame {...injectedProps} />} />
      <Route path="/widget" element={<Widget {...injectedProps} />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
