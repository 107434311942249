"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.seal = void 0;
const core_1 = require("@hpke/core");
const cryptoUtils_1 = require("./cryptoUtils");
const seal = async (data) => {
    const suite = new core_1.CipherSuite({
        kem: new core_1.DhkemP256HkdfSha256(),
        kdf: new core_1.HkdfSha256(),
        aead: new core_1.Aes256Gcm(),
    });
    if (!process.env.REACT_APP_METAKEEP_FIRMWARE_HPKE_PUBLIC_KEY)
        throw new Error("HPKE Public key is not set");
    const pkR = await suite.kem.importKey("jwk", JSON.parse(process.env.REACT_APP_METAKEEP_FIRMWARE_HPKE_PUBLIC_KEY), true);
    const sender = await suite.createSenderContext({
        recipientPublicKey: pkR,
    });
    const ct = await sender.seal(new TextEncoder().encode(data));
    return {
        cipherText: (0, cryptoUtils_1.bytesArrayToBase64)(ct),
        senderPublicKey: (0, cryptoUtils_1.bytesArrayToBase64)(sender.enc),
    };
};
exports.seal = seal;
