import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from "react";
import clx from "classnames";
import {
  AppLogo,
  CloseFrame,
  CustomModal,
  SecuredByFooter,
  CompletedState,
  SnackBar,
  Banner,
} from "../component";
import { useStore } from "../store";
import { shallow } from "zustand/shallow";
import { useSpring, animated } from "react-spring";

import { VisibilityTracker } from "../utils/visibiltyTracker";
import { RecoveryEmailWizard } from "../component/RecoveryEmailWizard";
import {
  PublicAppData,
  getAppTheme,
  getCachedAppDataByAppId,
} from "frontend-utils";
import {
  APP_HIDDEN,
  DARK_THEME,
  ENABLE_VISIBILITY_TRACKER,
  LIGHT_THEME,
  TABLET_WIDTH_BREAKPOINT,
} from "../constants";
import { logger } from "frontend-utils/logger";
import { FRAME_CSS_SELECTOR } from "../utils/cssUtils";
import { useScreenSize } from "../hooks";

const MIN_HEIGHT = "250px";

interface FrameLayoutProps {
  render: (props: {
    visibilityTracker: VisibilityTracker | null;
  }) => JSX.Element;
}

export const FrameLayout = ({ render }: FrameLayoutProps) => {
  const {
    frameState,
    loading,
    snackbarState,
    isMobileApp,
    showRecoveryEmailWizard,
    appTheme,
    setAppTheme,
    appVisibility,
    appId,
    showApprovalBanner,
    customCss,
  } = useStore(
    (state) => ({
      frameState: state.frameState,
      loading: state.loading,
      snackbarState: state.snackbarState,
      isMobileApp: state.isMobileApp,
      widgetSlice: state.widgetSlice,
      showRecoveryEmailWizard: state.showRecoveryEmailWizard,
      appTheme: state.appTheme,
      setAppTheme: state.setAppTheme,
      appVisibility: state.appVisibility,
      appId: state.appId,
      showApprovalBanner: state.showApprovalBanner,
      customCss: state.customCss,
    }),
    shallow
  );

  const snackBarOpen = snackbarState.open;
  const [style, animate] = useSpring(
    () => ({ height: isMobileApp ? "100%" : MIN_HEIGHT }),
    []
  );
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const intersectionRef = useRef(null);
  const hideBackdrop = appVisibility === APP_HIDDEN;

  const { width: windowWidth } = useScreenSize();
  const [visibilityTracker, setVisibilityTracker] =
    useState<VisibilityTracker | null>(null);
  /**
   * ResizeObserver
   * https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver
   */
  useEffect(() => {
    if (!wrapperRef || !wrapperRef.current) return;
    const { current } = wrapperRef;
    // Specifically for Tablets
    if (isMobileApp && current.clientWidth >= TABLET_WIDTH_BREAKPOINT) {
      current.style.border = "1px solid rgba(238, 238, 238, 1)";
      current.style.boxShadow = "0px 3px 12px rgb(51 51 51 / 10%)";
      current.style.margin = "auto";
    }
    if (isMobileApp) return;
    const resizeObserver = new ResizeObserver(() => {
      // This acts as a listner for when the height of
      // the wrapperRef updates due to content change
      // update the height of the frame accordingly
      // we are animating a div on top of wrapper div
      const { height } = current.getBoundingClientRect();

      animate.start({
        height: `${height}px`,
      });
    });
    resizeObserver.observe(current);
    return () => {
      if (resizeObserver) resizeObserver.disconnect();
    };
  }, [animate, loading, isMobileApp]);

  const setupAppTheme = useCallback(() => {
    try {
      const cachedAppData = appId
        ? getCachedAppDataByAppId(appId)
        : ({} as PublicAppData);
      const currentTheme = getAppTheme(cachedAppData);
      setAppTheme(currentTheme);
    } catch (err) {
      logger.log(err);
      setAppTheme(LIGHT_THEME);
    }
  }, [setAppTheme, appId]);

  useEffect(() => {
    setupAppTheme();
  }, [setupAppTheme]);

  useEffect(() => {
    if (!intersectionRef || !intersectionRef.current) return;
    const { current } = intersectionRef;
    let currentTracker: VisibilityTracker | null = null;
    if (ENABLE_VISIBILITY_TRACKER) {
      currentTracker = new VisibilityTracker(current);
      setVisibilityTracker(currentTracker);
    }
    return () => {
      if (currentTracker) currentTracker.stop();
    };
  }, []);

  const frameChild = useMemo(() => {
    if (frameState) return <CompletedState />;
    const child = showRecoveryEmailWizard ? (
      <RecoveryEmailWizard />
    ) : (
      render({ visibilityTracker })
    );
    return (
      <>
        <AppLogo />
        {child}
      </>
    );
  }, [frameState, render, showRecoveryEmailWizard, visibilityTracker]);

  // for mobile devices the i.e assuming window width < 500px border radius is always 0px
  // for cases where screen size is greater than 500px we apply the custom border radius
  // this covers tablets and desktops
  const applyCustomBorderRadius =
    !isMobileApp || windowWidth >= TABLET_WIDTH_BREAKPOINT;

  const frameContainerCss = customCss?.[FRAME_CSS_SELECTOR] || {};
  const customBackgroundColor = frameContainerCss["background-color"];
  const customBorderRadius = frameContainerCss["border-radius"];
  const customBorderRadiusStyle = {
    ...(customBorderRadius && applyCustomBorderRadius
      ? { borderRadius: customBorderRadius }
      : {}),
  };
  const customContainerStyle = {
    ...(customBackgroundColor
      ? { backgroundColor: customBackgroundColor }
      : {}),
    ...customBorderRadiusStyle,
  };

  const overflowValue = snackBarOpen ? "unset" : "hidden";

  return (
    <div
      className={clx("custom-overlay-layout", hideBackdrop && "transparent")}
    >
      <animated.div
        style={{
          ...style,
          overflow: overflowValue,
          backgroundColor: appTheme === DARK_THEME ? "#272833" : "#fff",
          boxShadow: "0px 3px 12px rgb(51 51 51 / 10%)",
          borderRadius: isMobileApp ? "0px" : "18.75px",
          ...(isMobileApp && {
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }),
          visibility: appVisibility,
          // animated div has diffrent sizes on different screen sizes
          // so we remove any unwanted custom css around the frame in case of mobile devices
          // Specifically used here to prevent unwanted css in case of Tablets
          ...(isMobileApp ? {} : customContainerStyle),
          position: "relative",
        }}
        ref={intersectionRef}
        id="frame_layout"
      >
        <div
          ref={wrapperRef}
          className={clx(
            "frame-wrapper",
            isMobileApp && "mobile-screen",
            appTheme === DARK_THEME && "dark-theme"
          )}
          style={{
            ...customContainerStyle,
          }}
        >
          {showApprovalBanner && <Banner />}
          <div
            className={clx(
              "inner-wrapper",
              isMobileApp && "inner-wrapper-mobile-screen"
            )}
          >
            <CloseFrame />
            <CloseModal />
            {frameChild}
            <SecuredByFooter />
            <SnackBar />
          </div>
        </div>
      </animated.div>
    </div>
  );
};

const CloseModal = () => {
  const showCloseModal = useStore((state) => state.showCloseModal);
  if (!showCloseModal) return null;
  return <CustomModal />;
};
