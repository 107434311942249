"use strict";
// This class is used to track the visibility of the iframe using the
// IntersectionObserverV2 API. It is used to determine when the iframe
// is visible and when it is not. This is used to prevent clickjacking attacks.
Object.defineProperty(exports, "__esModule", { value: true });
exports.VisibilityTracker = void 0;
const logger_1 = require("frontend-utils/logger");
const constants_1 = require("../constants");
class VisibilityTracker {
    constructor(element) {
        // The amount of time in ms that the iframe must be visible before
        // we consider it to be visible. This is to prevent the iframe from
        // being considered visible when it is scrolled into view but not
        // actually visible to the user.
        // Human reaction time is ~200ms, but users also have to read the
        // message and click the button. 800ms is a good balance between
        // preventing clickjacking and not being too annoying to the user.
        this.VISIBILITY_THRESHOLD_MS = 800;
        if (!element)
            throw new Error("Target element required");
        logger_1.logger.log("VisibilityTracker.constructor", element);
        // The element to track
        this.element = element;
        // IntersectionObserver instance
        this.intersectionObserver = null;
        // Time in ms since the element became visible
        this.visibleSinceMs = 0;
    }
    start() {
        logger_1.logger.log("VisibilityTracker.start");
        if (!this.intersectionObserver) {
            this.intersectionObserver = new IntersectionObserver(
            // @ts-ignore IntersectionObserverV2 is not supported by typescript
            this.onIntersection.bind(this), {
                // Whole element must be visible
                // Set to 0.99 because 1 doesn't work well on safari
                threshold: [0.99],
                // Track visibility changes
                // @ts-ignore IntersectionObserverV2 is not supported by typescript
                trackVisibility: true,
                // Set a minimum delay between notifications
                delay: 100,
            });
            this.intersectionObserver.observe(this.element);
        }
    }
    stop() {
        logger_1.logger.log("VisibilityTracker.stop");
        this.visibleSinceMs = 0;
        if (this.intersectionObserver) {
            this.intersectionObserver.unobserve(this.element);
            this.intersectionObserver = null;
        }
    }
    onIntersection(entries) {
        for (const entry of entries) {
            logger_1.logger.log("VisibilityTracker.onIntersection", entry);
            // Feature detection
            if (typeof entry.isVisible === "undefined") {
                logger_1.logger.log("VisibilityTracker.onIntersection: V2 api not supported, falling back to V1 behavior");
                // The browser doesn't support Intersection Observer v2, falling back to v1 behavior.
                entry.isVisible = true;
            }
            if (entry.isIntersecting && entry.isVisible) {
                this.visibleSinceMs = entry.time;
                logger_1.logger.log("VisibilityTracker.onIntersection: visible since", this.visibleSinceMs);
            }
            else {
                logger_1.logger.log("VisibilityTracker.onIntersection: not visible");
                this.visibleSinceMs = 0;
            }
        }
    }
    isVisible() {
        const visible = constants_1.ENABLE_VISIBILITY_TRACKER
            ? this.intersectionObserver &&
                this.visibleSinceMs > 0 &&
                performance.now() - this.visibleSinceMs >= this.VISIBILITY_THRESHOLD_MS
            : true;
        logger_1.logger.log("VisibilityTracker.isVisible", visible);
        return visible;
    }
}
exports.VisibilityTracker = VisibilityTracker;
