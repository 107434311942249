import { StateCreator } from "zustand";
import { StoreInterface } from "./interfaces";

export enum WIDGET_TYPE {
  // SIGN was renamed to APPROVAL
  // It's here for backward compatibility
  SIGN = "SIGN",
  APPROVAL = "APPROVAL",
  CONSENT = "CONSENT",
  LOGIN = "LOGIN",
  PREFETCH = "PREFETCH",
}

export interface WidgetSlice {
  widgetType: WIDGET_TYPE | null;
  setType: (type: WIDGET_TYPE) => void;
}

export const widgetSlice: StateCreator<StoreInterface, [], [], WidgetSlice> = (
  set
) => ({
  widgetType: null,
  setType: (type) =>
    set((state) => ({
      widgetSlice: { ...state.widgetSlice, widgetType: type },
    })),
});
