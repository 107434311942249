"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.signRequest = exports.hashRequest = exports.signMessage = exports.base64ToHex = exports.getCurvePointsFromPublicKey = exports.importPrivateKey = exports.generateKeyPair = exports.compressPublicKey = exports.bytesArrayToBase64 = exports.base64ToBytesArray = exports.exportKey = exports.keyAlgorithm = void 0;
const elliptic_1 = require("elliptic");
const EC = new elliptic_1.ec("p256");
//algo for key generation
exports.keyAlgorithm = {
    name: "ECDSA",
    namedCurve: "P-256",
};
//algo for msg signing
const signAlgorithm = {
    name: "ECDSA",
    hash: { name: "SHA-256" },
};
/**
 *
 * @param {CryptoKey} key to export
 * @param {string} format can be one of "jwk, "raw", "spki", "pkcs8"
 * @returns {Promise<ArrayBuffer | JsonWebKey>} returns key in either ArrayBuffer or JsonWebKey format
 * @description https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/exportKey
 */
const exportKey = async (key, format) => await window.crypto.subtle.exportKey(format, key);
exports.exportKey = exportKey;
/**
 *
 * @param {String} bs64String Base64 string
 * @returns ArrayBuffer
 */
const base64ToBytesArray = (bs64String) => {
    const decodedString = window.atob(bs64String);
    const buf = new ArrayBuffer(decodedString.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = decodedString.length; i < strLen; i++) {
        bufView[i] = decodedString.charCodeAt(i);
    }
    return buf;
};
exports.base64ToBytesArray = base64ToBytesArray;
const bytesArrayToBase64 = (bytes) => window.btoa(String.fromCharCode(...new Uint8Array(bytes)));
exports.bytesArrayToBase64 = bytesArrayToBase64;
/**
 * @param {ArrayBuffer} rawPublicKey ArrayBuffer
 * @refrence https://gist.github.com/shanewholloway/6ed5a52fa985b1d23024daa001b6a51e
 * @refrence https://stackoverflow.com/questions/17171542/algorithm-for-elliptic-curve-point-compression/30431547#30431547
 * @returns ArrayBuffer of public key
 *
 */
const compressPublicKey = (rawPublicKey) => {
    const u8full = new Uint8Array(rawPublicKey);
    const len = u8full.byteLength;
    const u8 = u8full.slice(0, (1 + len) >>> 1); // drop `y`
    u8[0] = 0x2 | (u8full[len - 1] & 0x01); // encode sign of `y` in first bit
    return u8.buffer;
};
exports.compressPublicKey = compressPublicKey;
const generateKeyPair = async (isExtractable) => {
    const { privateKey, publicKey } = await window.crypto.subtle.generateKey(exports.keyAlgorithm, isExtractable, ["sign", "verify"]);
    // export the public key in raw format
    const pubK = (await (0, exports.exportKey)(publicKey, "raw"));
    const compressedB64PublicKey = (0, exports.bytesArrayToBase64)((0, exports.compressPublicKey)(pubK));
    if (isExtractable) {
        let extractablePrivateKey = (await (0, exports.exportKey)(privateKey, "jwk")).d;
        return {
            publicKey: compressedB64PublicKey,
            privateKey: extractablePrivateKey,
        };
    }
    return {
        publicKey: compressedB64PublicKey,
        privateKey: privateKey,
    };
};
exports.generateKeyPair = generateKeyPair;
/**
 *
 * @param {String} d - jwk key
 * @param {String} x - x coordinate
 * @param {String} y - y coordinate
 * @returns CryptoKey Private crypto key
 * @description https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/importKey
 */
const importPrivateKey = async (d, x, y) => {
    const pvtJWK = {
        crv: exports.keyAlgorithm.namedCurve,
        d,
        ext: true,
        key_ops: ["sign"],
        kty: "EC",
        x,
        y,
    };
    return await window.crypto.subtle.importKey("jwk", pvtJWK, exports.keyAlgorithm, true, ["sign"]);
};
exports.importPrivateKey = importPrivateKey;
/**
 *
 * @param {String} bs64Key Public Key in Base64 format
 * @returns x: String, y: String EC coordinates
 */
const getCurvePointsFromPublicKey = async (arrayBuffer) => {
    const publicRawKey = await window.crypto.subtle.importKey("raw", arrayBuffer, exports.keyAlgorithm, true, ["verify"]);
    const pubJWK = (await (0, exports.exportKey)(publicRawKey, "jwk"));
    if (!pubJWK.x || !pubJWK.y) {
        throw new Error("Invalid public key");
    }
    return { x: pubJWK.x, y: pubJWK.y };
};
exports.getCurvePointsFromPublicKey = getCurvePointsFromPublicKey;
const base64ToHex = (bs64Str) => {
    const raw = window.atob(bs64Str);
    let result = "";
    for (let i = 0; i < raw.length; i++) {
        const hex = raw.charCodeAt(i).toString(16);
        result += hex.length === 2 ? hex : "0" + hex;
    }
    return result.toUpperCase();
};
exports.base64ToHex = base64ToHex;
/**
 *
 * @param {String} message - message to sign
 * @param {String} secretKey - private key secret
 * @param {String} publicKey - public key in Base64 format
 * @returns String - signature in Base64 format
 */
const signMessage = async (message = "Hello", secretKey, pubKeyB64) => {
    const hexString = (0, exports.base64ToHex)(pubKeyB64);
    const keyPair = EC.keyFromPublic(hexString, "hex");
    const publicKeyBuffer = new Uint8Array(keyPair.getPublic("array"));
    const { x, y } = await (0, exports.getCurvePointsFromPublicKey)(publicKeyBuffer);
    const privateKey = await (0, exports.importPrivateKey)(secretKey, x, y);
    const msgArrayBuffer = new TextEncoder().encode(message);
    const signedMsg = await window.crypto.subtle.sign(signAlgorithm, privateKey, msgArrayBuffer);
    return (0, exports.bytesArrayToBase64)(signedMsg);
};
exports.signMessage = signMessage;
/**
 * Generates a SHA-256 hash of a request payload concatenated with other request metadata.
 * @param {Object} request - The request object to hash.
 * @param {String} request.hostname - The hostname of the request.
 * @param {String} request.method - The HTTP method of the request (e.g., GET, POST).
 * @param {String} request.path - The path of the request URL.
 * @param {String} request.timestampHeader - The timestamp of the request.
 * @param {String} request.requestData - The payload or data of the request.
 * @returns {Promise<ArrayBuffer>} - A promise that resolves with the SHA-256 hash of the concatenated data.
 */
const hashRequest = async ({ hostname, method, path, timestampHeader, requestData, }) => await window.crypto.subtle.digest("SHA-256", new TextEncoder().encode(`${hostname}\n${method}\n${path}\n${timestampHeader}\n${requestData}`));
exports.hashRequest = hashRequest;
/**
 * @param {Object} request - request object to sign
 * @param {String} secretKey - private key secret
 * @param {String} publicKey - public key in Base64 format
 * @returns String - signature in Base64 format
 */
const signRequest = async (request, sessionKey, timestampHeader, method, path, hostname) => {
    const hashedRequest = await (0, exports.hashRequest)({
        hostname,
        method,
        path,
        timestampHeader,
        requestData: request,
    });
    try {
        const signedMsg = await window.crypto.subtle.sign(signAlgorithm, sessionKey.privateKey, new Uint8Array(hashedRequest));
        return (0, exports.bytesArrayToBase64)(signedMsg);
    }
    catch (error) {
        console.error("Failed to sign the request:", error);
    }
};
exports.signRequest = signRequest;
