"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initializeFrameAction = exports.setupB64RequestAction = exports.fetchPublicAppDataAction = exports.setupUserAction = exports.setupApprovalFrameAction = exports.setupPrefetchFrameAction = exports.setupLoginFrameAction = exports.setupConsentFrameAction = void 0;
const frontend_utils_1 = require("frontend-utils");
const api_1 = require("../api");
const constants_1 = require("../constants");
const utils_1 = require("../utils");
const logger_1 = require("frontend-utils/logger");
const cssUtils_1 = require("../utils/cssUtils");
const fontUtils_1 = require("../utils/fontUtils");
const approvalFrameSlice_1 = require("./approvalFrameSlice");
const widgetSlice_1 = require("./widgetSlice");
const INVALID_REQUEST_RESPONSE = { status: constants_1.INVALID_REQUEST };
const INVALID_REASON_RESPONSE = { status: constants_1.INVALID_REASON };
// Validates the user and returns the parsed user if valid
// else sends an error message and returns null user and error message
// This function assumed that an empty user is a valid user since it's not a required field
const validateUser = (user) => {
    //  If user is empty, assume it's a valid user.
    // Check if there are no keys in the user object or all the values are falsy
    if (!Object.keys(user).length || !Object.values(user).some(Boolean))
        return {
            parsedUser: null,
            error: null,
        };
    // Try to parse the user
    try {
        return {
            parsedUser: new frontend_utils_1.ParsedUser(user),
            error: null,
        };
    }
    catch (error) {
        if (error.message === frontend_utils_1.INVALID_EMAIL_ERROR) {
            (0, utils_1.postMessage)(constants_1.ERROR, { status: constants_1.INVALID_EMAIL }, true);
            return {
                parsedUser: null,
                error: constants_1.INVALID_EMAIL,
            };
        }
        if (error.message === frontend_utils_1.INVALID_PHONE_ERROR) {
            (0, utils_1.postMessage)(constants_1.ERROR, { status: constants_1.INVALID_PHONE }, true);
            return {
                parsedUser: null,
                error: constants_1.INVALID_PHONE,
            };
        }
        (0, utils_1.postMessage)(constants_1.ERROR, { status: constants_1.INVALID_REQUEST }, true);
        return {
            parsedUser: null,
            error: constants_1.INVALID_REQUEST,
        };
    }
};
// All frame setup actions are in this file
// Note - By default all iframe width and height are set to 0 x 0
// Make sure you call the postMessage with MAKE_FRAME_VISIBLE event
// at the beginning of the actions, exceptions are prefetch frame and login frame
// Prefetch frame is always hidden and login frame is shown only when user is not present in payload
// setAppToVisible sets the internal state of iframe to visible
// this helps us to set required states for theme of the app
const setupConsentFrameAction = (_, getState, __) => async (payload = {}) => {
    const { setAppId, setAppToVisible, setConsentData, setLoading, fetchPublicAppData, setUser, setFrameState, setConsentToken, setAppInfo, } = getState();
    try {
        (0, utils_1.postMessage)(constants_1.MAKE_FRAME_VISIBLE);
        const { appId = null, consentToken = null } = payload;
        setLoading(true);
        setAppId(appId);
        setAppToVisible();
        if (!consentToken || consentToken === "null") {
            // if no consent token is found either in params or in postMessage
            const response = { status: constants_1.NO_TOKEN_FOUND };
            (0, utils_1.postMessage)(constants_1.ERROR, response, true);
            setFrameState({
                state: constants_1.NO_TOKEN_FOUND,
                frameResponse: response,
            });
            return;
        }
        setConsentToken(consentToken);
        const { tokenData, appInfo } = (0, utils_1.parseConsentData)(await (0, api_1.cachedConsentData)(consentToken));
        setConsentData(tokenData);
        setUser(new frontend_utils_1.ParsedUser((tokenData === null || tokenData === void 0 ? void 0 : tokenData.fromUser) || {}));
        // For Newer SDK users the appId is present in requestB64 params or postMessage
        // and we use appData from the appId present in message or params.
        // For Legacy SDK users appId is not present in requestB64 params or postMessage
        // and we use appData present in response from getConsentTokenData Api.
        if (appId)
            await fetchPublicAppData({ appId });
        else
            setAppInfo(appInfo);
    }
    catch (err) {
        (0, utils_1.handleErrorState)(err);
    }
    finally {
        setLoading(false);
    }
};
exports.setupConsentFrameAction = setupConsentFrameAction;
const setupLoginFrameAction = (_, getState, __) => async (payload) => {
    const { setLoading, setAppId, setAppToVisible, fetchPublicAppData, setupUser, } = getState();
    try {
        const { appId, user = {}, internalOptions = {} } = payload;
        const { bypassAppDataCache = false } = internalOptions;
        if (!appId) {
            (0, utils_1.postMessage)(constants_1.ERROR, { status: constants_1.APP_ID_REQUIRED }, true);
            return;
        }
        logger_1.logger.log("setupLoginFrameAction: Setting up login frame with payload: ", payload);
        // The callback on the Android mobile SDK requires a valid app id to be present.
        // So we validate and set the appId in the beginning before doing rest of the validation.
        setAppId(appId);
        // User validation
        const { parsedUser, error } = validateUser(user);
        // If there's an error, return
        // The error message is already sent in the validateUser function
        if (error) {
            logger_1.logger.log("setupLoginFrameAction: Error in user validation", error);
            return;
        }
        setLoading(true);
        if (!parsedUser) {
            logger_1.logger.log("setupLoginFrameAction: no user provided, setting app to visible");
            (0, utils_1.postMessage)(constants_1.MAKE_FRAME_VISIBLE);
            setAppToVisible();
            // we don't need to fetch app details if the user is provided, as the frame never shows up
            await fetchPublicAppData({ appId, bypassCache: bypassAppDataCache });
        }
        await setupUser(parsedUser);
    }
    catch (err) {
        logger_1.logger.log(err);
    }
    finally {
        setLoading(false);
    }
};
exports.setupLoginFrameAction = setupLoginFrameAction;
const setupPrefetchFrameAction = (_, getState, __) => async (payload) => {
    const { setAppId } = getState();
    setAppId(payload.appId);
};
exports.setupPrefetchFrameAction = setupPrefetchFrameAction;
// setup approval frame action
// sets approval frame slice state
const setupApprovalFrameAction = (_, getState, __) => async (options) => {
    const { appId, user = {}, reason, payload, type, description } = options;
    const { setLoading, setAppId, setAppToVisible, fetchPublicAppData, setupUser, approvalFrameSlice, setFrameState, } = getState();
    (0, utils_1.postMessage)(constants_1.MAKE_FRAME_VISIBLE);
    const { setApprovalFramePayload, toggleGetWallet } = approvalFrameSlice;
    logger_1.logger.debug("setupApprovalFrameAction: Setting up approval frame with payload, type, reason, description: ", payload, type, reason, description);
    try {
        if (!appId) {
            const response = { status: constants_1.APP_ID_REQUIRED };
            (0, utils_1.postMessage)(constants_1.ERROR, response, true);
            setFrameState({ state: response.status, frameResponse: response });
            return;
        }
        // The callback on the Android mobile SDK requires a valid app id to be present.
        // So we validate and set the appId in the beginning before doing rest of the validation.
        setAppId(appId);
        if (!payload) {
            (0, utils_1.postMessage)(constants_1.ERROR, INVALID_REQUEST_RESPONSE, true);
            setFrameState({
                state: INVALID_REQUEST_RESPONSE.status,
                frameResponse: INVALID_REQUEST_RESPONSE,
            });
            return;
        }
        // Validate the top level reason
        if (!reason && !(description === null || description === void 0 ? void 0 : description.text)) {
            (0, utils_1.postMessage)(constants_1.ERROR, INVALID_REASON_RESPONSE, true);
            setFrameState({
                state: INVALID_REASON_RESPONSE.status,
                frameResponse: INVALID_REASON_RESPONSE,
            });
            return;
        }
        // Validate the reason for multiple transactions request
        if (type === approvalFrameSlice_1.APPROVAL_REQUEST_TYPE.SIGN_TRANSACTION_MULTIPLE) {
            if (payload.length === 0) {
                (0, utils_1.postMessage)(constants_1.ERROR, INVALID_REQUEST_RESPONSE, true);
                setFrameState({
                    state: INVALID_REQUEST_RESPONSE.status,
                    frameResponse: INVALID_REQUEST_RESPONSE,
                });
                return;
            }
            for (let index = 0; index < payload.length; index++) {
                if (!payload[index].reason) {
                    (0, utils_1.postMessage)(constants_1.ERROR, INVALID_REASON_RESPONSE, true);
                    setFrameState({
                        state: INVALID_REASON_RESPONSE.status,
                        frameResponse: INVALID_REASON_RESPONSE,
                    });
                    return;
                }
            }
        }
        // User validation
        const { parsedUser, error } = validateUser(user);
        // If there's an error, return
        // The error message is already sent in the validateUser function
        if (error) {
            logger_1.logger.log("setupApprovalFrameAction: Error in user validation", error);
            return;
        }
        setLoading(true);
        setAppToVisible();
        await fetchPublicAppData({ appId });
        await setupUser(parsedUser, () => toggleGetWallet(true));
        setApprovalFramePayload({
            type,
            payload,
            reason,
            description,
        });
    }
    catch (err) {
        console.log(err);
    }
    finally {
        setLoading(false);
    }
};
exports.setupApprovalFrameAction = setupApprovalFrameAction;
// Setup user action
// Will set the user if it's provided in the payload
// else, try to read of the logged in user if there's any.
// If no user is found, it will call the callback function if provided
const setupUserAction = (_, getState, __) => async (parsedUser, onNoUserFoundCb) => {
    const { setUserProvided, setUser } = getState();
    // Check if the user is provided
    if (parsedUser) {
        // Provided user is valid, set the user and return true
        setUserProvided(true);
        setUser(parsedUser);
        return;
    }
    // If no user is provided, try to get the user from the session
    try {
        const userFromSession = await (0, utils_1.getUserFromSession)();
        if (userFromSession)
            setUser(userFromSession);
    }
    catch (err) {
        if (onNoUserFoundCb && typeof onNoUserFoundCb === "function")
            onNoUserFoundCb();
    }
    return;
};
exports.setupUserAction = setupUserAction;
// fetch app details action
// sets app info in appSlice
// If `bypassCache` is true, it will fetch the app details from the server
// Else, it will first look for the app details in the localStorage. If found will return cached data and in the background will get and update the app details in localStorage.
// If not found in the local storage, it will get the latest app data and also save that to the local storage for future use.
const fetchPublicAppDataAction = (_, getState, __) => async (payload) => {
    var _a, _b, _c;
    const { appId, bypassCache = false } = payload;
    if (!appId)
        throw new Error(constants_1.APP_ID_REQUIRED);
    const { setAppInfo, toggleApprovalBanner } = getState();
    try {
        const appInfo = (await (0, api_1.getAppDetails)(appId, bypassCache)) || {};
        const appTheme = (0, frontend_utils_1.getAppTheme)(appInfo);
        const parsedCss = (0, cssUtils_1.getParsedCustomCss)(((_a = appInfo === null || appInfo === void 0 ? void 0 : appInfo.userWallet) === null || _a === void 0 ? void 0 : _a.customCss) || {});
        if (constants_1.WALLET_APPROVAL_CHECK_ENABLED) {
            const { originApprovalStatus = "", appIdApprovalStatus = "" } = appInfo || {};
            toggleApprovalBanner(originApprovalStatus !== constants_1.APPROVAL_STATUS_MAP.APPROVED ||
                appIdApprovalStatus !== constants_1.APPROVAL_STATUS_MAP.APPROVED);
        }
        const customFontFamilyData = ((_b = appInfo === null || appInfo === void 0 ? void 0 : appInfo.userWallet) === null || _b === void 0 ? void 0 : _b.fontFamilyData) || {};
        const { fontName = null, url = null } = customFontFamilyData;
        if (fontName && url && fontName !== constants_1.DEFAULT_USER_WALLET_FONT)
            await (0, fontUtils_1.generateFontFaceAndLoad)(fontName, url);
        const fontSizeScaling = (_c = appInfo === null || appInfo === void 0 ? void 0 : appInfo.userWallet) === null || _c === void 0 ? void 0 : _c.fontSizeScaling;
        // Default font scale factor is 1
        if (fontSizeScaling && fontSizeScaling !== 1)
            (0, fontUtils_1.updateRootFontSize)(parseFloat(fontSizeScaling));
        setAppInfo(Object.assign(Object.assign({}, appInfo), { appId,
            appTheme, customCss: parsedCss }));
    }
    catch (err) {
        (0, utils_1.handleErrorState)(err);
    }
};
exports.fetchPublicAppDataAction = fetchPublicAppDataAction;
// in case of b64 request this will be called from App.js
// it will call initializeFrameAction based on widgetType
const setupB64RequestAction = (_, getState, __) => (request, pathname) => {
    const { payload } = request;
    const { initializeFrame, setupRequestB64Slice, setFrameState } = getState();
    try {
        // get widgetType based on the pathname in the url
        const widgetType = (0, utils_1.getWidgetTypeFromPathname)(pathname);
        const options = Object.assign(Object.assign({}, payload), { widgetType });
        setupRequestB64Slice(request);
        initializeFrame(options);
    }
    catch (err) {
        const response = { status: constants_1.INVALID_REQUEST };
        setFrameState({ state: response.status, frameResponse: response });
        (0, utils_1.postMessage)(constants_1.ERROR, response, true);
    }
};
exports.setupB64RequestAction = setupB64RequestAction;
// Common method that handles which frame state needs to be setup
// based on widgetType
const initializeFrameAction = (_, getState, __) => async (payload) => {
    const { widgetSlice, setFrameState } = getState();
    const { setType } = widgetSlice;
    const { widgetType } = payload;
    setType(widgetType);
    switch (widgetType) {
        case widgetSlice_1.WIDGET_TYPE.PREFETCH:
            const { setupPrefetchFrame } = getState();
            setupPrefetchFrame(payload);
            break;
        case widgetSlice_1.WIDGET_TYPE.CONSENT:
            const { setupConsentFrame } = getState();
            setupConsentFrame(payload);
            break;
        case widgetSlice_1.WIDGET_TYPE.LOGIN:
            const { setupLoginFrame } = getState();
            setupLoginFrame(payload);
            break;
        case widgetSlice_1.WIDGET_TYPE.SIGN:
        case widgetSlice_1.WIDGET_TYPE.APPROVAL:
            const { setupApprovalFrame } = getState();
            setupApprovalFrame(payload);
            break;
        default:
            const response = { status: constants_1.INVALID_REQUEST };
            setFrameState({ state: response.status, frameResponse: response });
            (0, utils_1.postMessage)(constants_1.ERROR, response, true);
    }
};
exports.initializeFrameAction = initializeFrameAction;
