// This file should not be renamed to index.ts to avoid issues with toast rendering
import { createWithEqualityFn } from "zustand/traditional";
import { authSlice } from "./authSlice";
import { requestSlice } from "./requestSlice";
import { toast } from "react-toastify";
import { CONSENT_TYPE_COIN_TRANSFER } from "../constants";
import {
  setupUserAction,
  fetchPublicAppDataAction,
  setupPrefetchFrameAction,
  setupApprovalFrameAction,
  setupLoginFrameAction,
  setupConsentFrameAction,
  initializeFrameAction,
  setupB64RequestAction,
} from "./actions";
import { StateCreator } from "zustand";
import {
  ConsentSlice,
  ModalSlice,
  SnackbarSlice,
  StoreInterface,
} from "./interfaces";
import { approvalFrameSlice } from "./approvalFrameSlice";
import { appSlice } from "./appSlice";
import { widgetSlice } from "./widgetSlice";
import { frameSlice } from "./frameSlice";

export const TOAST_ID = "snackbar-toast";

const consentSlice: StateCreator<ConsentSlice, [], [], ConsentSlice> = (
  set
) => ({
  consentData: {
    // We set the default consent type to coin transfer
    // Otherwise consent frame size is too small when the consent
    // data is loading.
    type: CONSENT_TYPE_COIN_TRANSFER,
    toDetails: {},
    developerName: "",
    contractData: {},
  },
  consentToken: null,
  setConsentData: (payload) => set(() => ({ consentData: { ...payload } })),
  setConsentToken: (payload) => set(() => ({ consentToken: payload })),
});

const modalSlice: StateCreator<ModalSlice, [], [], ModalSlice> = (set) => ({
  showAuthCodeModal: false,
  showCloseModal: false,
  setAuthModalState: (payload) => set(() => ({ showAuthCodeModal: payload })),
  setCloseModalState: (payload) => set(() => ({ showCloseModal: payload })),
});

const snackbarSlice: StateCreator<SnackbarSlice, [], [], SnackbarSlice> = (
  set
) => ({
  snackbarState: {
    open: false,
    onCompleteCb: null,
  },
  setSnackbarState: (payload) => {
    const { open, message, onCompleteCb, toastOpts = {} } = payload;
    set(() => ({ snackbarState: { open, onCompleteCb, toastOpts } }));
    toast(message, {
      toastId: TOAST_ID,
      ...toastOpts,
    });
  },
});

//TODO structure all slices in a way that only state objects are inside the slice
// and all setter functions are outside the slice
// This will make the global state tree cleaner
// further we can make all setter actions

export const useStore = createWithEqualityFn<StoreInterface>((...props) => ({
  ...appSlice(...props),
  ...authSlice(...props),
  ...frameSlice(...props),
  ...modalSlice(...props),
  ...consentSlice(...props),
  ...requestSlice(...props),
  ...snackbarSlice(...props),
  widgetSlice: { ...widgetSlice(...props) },
  approvalFrameSlice: { ...approvalFrameSlice(...props) },
  setupUser: setupUserAction(...props),
  setupApprovalFrame: setupApprovalFrameAction(...props),
  initializeFrame: initializeFrameAction(...props),
  setupLoginFrame: setupLoginFrameAction(...props),
  setupConsentFrame: setupConsentFrameAction(...props),
  setupB64RequestState: setupB64RequestAction(...props),
  setupPrefetchFrame: setupPrefetchFrameAction(...props),
  fetchPublicAppData: fetchPublicAppDataAction(...props),
}));
