"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.authSlice = void 0;
const frontend_utils_1 = require("frontend-utils");
const constants_1 = require("../constants");
const storageHelper_1 = require("../utils/storageHelper");
const _1 = require(".");
const RECOVERY_SKIP_TIMEOUT_MILLIS = 7 * 24 * 60 * 60 * 1000; // 7 days
//TODO
// Control all auth calls from here
// There should be just once function that takes care of signing in the user.
// Right now each container calls sign in and then opens up the verify OTP view.
// This will not scale when we add more logging in options like SMS< Google Sign-in etc.
// sendCustomChallengeAnswer requires some value
// sent as an answer
const authSlice = (set, get) => ({
    user: null,
    userProvided: false,
    sessionId: null,
    showRecoveryEmailWizard: false,
    recoveryOptionsAvailable: false,
    signinRequestNetworkState: constants_1.NETWORK_STATES.IDLE,
    usePasskey: false,
    setUsePasskey: (usePasskey) => set(() => ({ usePasskey })),
    toggleRecoveryEmailWizard: (payload) => set(() => ({ showRecoveryEmailWizard: payload })),
    setSessionId: (payload) => set(() => ({ sessionId: payload })),
    setUser: (payload) => set(() => ({ user: payload })),
    setUserProvided: (payload) => set(() => ({ userProvided: payload })),
    setSigninRequestNetworkState: (payload) => set(() => ({ signinRequestNetworkState: payload })),
    signinUser: async () => {
        var _a, _b;
        const { user, setSessionId, appId, setFrameState, setSigninRequestNetworkState, } = get();
        setSigninRequestNetworkState(constants_1.NETWORK_STATES.PENDING);
        try {
            const requesterDomain = _1.useStore.getState().requesterDomain;
            let recoveryOptionsAvailable = false;
            if (!appId || !user) {
                throw new Error("App ID or user is missing");
            }
            // Call the sign in function
            const { sessionId, recoveryOptionsAvailable: metakeepRecoveryOptionsAvailable, } = await frontend_utils_1.MetakeepAuth.signIn({
                parsedUser: user,
                appId: appId,
                requesterDomain: requesterDomain || "",
            });
            recoveryOptionsAvailable = metakeepRecoveryOptionsAvailable;
            // Set the session ID
            if (!sessionId) {
                throw new Error("Session ID is missing in response");
            }
            setSessionId(sessionId);
            set(() => ({
                recoveryOptionsAvailable,
            }));
            setSigninRequestNetworkState(constants_1.NETWORK_STATES.IDLE);
        }
        catch (err) {
            const status = (_b = (_a = err.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.status;
            if (status === null || status === void 0 ? void 0 : status.includes(constants_1.MAX_FAILED_LOGIN_ATTEMPTS)) {
                setFrameState({ state: constants_1.MAX_FAILED_LOGIN_ATTEMPTS });
            }
            else if (status === null || status === void 0 ? void 0 : status.includes(constants_1.TOO_MANY_LOGIN_REQUESTS)) {
                setFrameState({ state: constants_1.TOO_MANY_LOGIN_REQUESTS });
            }
            else {
                setSigninRequestNetworkState(constants_1.NETWORK_STATES.FAILED);
                setFrameState({ state: constants_1.SOMETHING_WENT_WRONG });
            }
        }
    },
    onSkipRecoveryEmail: (skipTimeout = false) => {
        const { setAuthModalState, toggleRecoveryEmailWizard, user } = get();
        const user_identifier = user === null || user === void 0 ? void 0 : user.serializedUser;
        setAuthModalState(false);
        toggleRecoveryEmailWizard(false);
        if (skipTimeout)
            return;
        const skipRecoveryKey = `${constants_1.SKIP_RECOVERY_TIMEOUT_KEY}_${user_identifier}`;
        // ask after 7 days next
        const timeout = Date.now() + RECOVERY_SKIP_TIMEOUT_MILLIS;
        storageHelper_1.storageWrapper.setItem(skipRecoveryKey, timeout.toString());
    },
});
exports.authSlice = authSlice;
