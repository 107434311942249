"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.frameSlice = void 0;
const constants_1 = require("../constants");
const frameSlice = (set) => ({
    frameState: null,
    appVisibility: constants_1.APP_HIDDEN,
    loading: true,
    setAppToVisible: () => set(() => ({ appVisibility: constants_1.APP_VISIBLE })),
    setFrameState: (payload) => set(() => ({ frameState: payload })),
    setLoading: (payload) => set(() => ({ loading: payload })),
});
exports.frameSlice = frameSlice;
