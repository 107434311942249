import cssescape from "cssesc";

export const addImportant = (value) => `${value} !important`;

export const BUTTON_CSS_SELECTORS = {
  PRIMARY_BTN: ".primary-button",
  PRIMARY_BTN_HOVER: ".primary-button:hover",
  PRIMARY_BTN_OUTLINED: ".primary-button-outlined",
  PRIMARY_BTN_OUTLINED_HOVER: ".primary-button-outlined:hover",
};

export const FRAME_CSS_SELECTOR = ".frame-container";

// Inline styles don't support !important, skip adding important
// in these cases
export const INLINE_STYLE_SELECTORS = [FRAME_CSS_SELECTOR];

export const getParsedCustomCss = (cssObject = {}) => {
  const parsedCss = {};
  Object.keys(cssObject).forEach((key) => {
    parsedCss[key] = {};
    Object.keys(cssObject[key]).forEach((cssKey) => {
      parsedCss[key][cssKey] = INLINE_STYLE_SELECTORS.includes(key)
        ? cssescape(cssObject[key][cssKey])
        : addImportant(cssescape(cssObject[key][cssKey]));
    });
  });
  return parsedCss;
};
