"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validator = void 0;
const frontend_utils_1 = require("frontend-utils");
/**
 * @author: nadeem@passbird.co
 * @param {string} fieldType  field type to be validated
 * @param {string} value  value for the field
 * @returns error object
 */
const validator = (fieldType, value) => {
    const errors = {};
    switch (fieldType) {
        case "email":
            try {
                new frontend_utils_1.ParsedUser({
                    email: value,
                });
                errors[fieldType] = null;
            }
            catch (error) {
                errors[fieldType] = "Please enter a valid email address";
            }
            break;
        case "phone":
            try {
                new frontend_utils_1.ParsedUser({
                    phone: value,
                });
                errors[fieldType] = null;
            }
            catch (error) {
                errors[fieldType] = "Please enter a valid phone number";
            }
            break;
        case "code":
            errors[fieldType] = "Please enter a valid 6 digit Code";
            if (value && value.length === 6)
                errors[fieldType] = null;
            break;
        default:
            return errors;
    }
    return errors;
};
exports.validator = validator;
