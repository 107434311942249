"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.approvalFrameSlice = exports.initialApprovalFrameSlice = exports.APPROVAL_REQUEST_TYPE = void 0;
var APPROVAL_REQUEST_TYPE;
(function (APPROVAL_REQUEST_TYPE) {
    APPROVAL_REQUEST_TYPE["SIGN_MESSAGE"] = "SIGN_MESSAGE";
    APPROVAL_REQUEST_TYPE["SIGN_TRANSACTION"] = "SIGN_TRANSACTION";
    APPROVAL_REQUEST_TYPE["SIGN_TRANSACTION_MULTIPLE"] = "SIGN_TRANSACTION_MULTIPLE";
    APPROVAL_REQUEST_TYPE["SIGN_TYPED_DATA"] = "SIGN_TYPED_DATA";
    APPROVAL_REQUEST_TYPE["DECRYPT"] = "DECRYPT";
})(APPROVAL_REQUEST_TYPE || (exports.APPROVAL_REQUEST_TYPE = APPROVAL_REQUEST_TYPE = {}));
exports.initialApprovalFrameSlice = {
    type: null, // request type message | transaction | typedData | decrypt
    payload: null, // approval request payload
    // One of the reason or description is required
    reason: null, // reason for approval request
    description: { text: "" }, // description of the approval request
    showGetWallet: false,
};
const approvalFrameSlice = (set) => (Object.assign(Object.assign({}, exports.initialApprovalFrameSlice), { setApprovalFramePayload: (payload) => set((state) => ({
        approvalFrameSlice: Object.assign(Object.assign({}, state.approvalFrameSlice), payload),
    })), toggleGetWallet: (payload) => set((state) => ({
        approvalFrameSlice: Object.assign(Object.assign({}, state.approvalFrameSlice), { showGetWallet: payload }),
    })) }));
exports.approvalFrameSlice = approvalFrameSlice;
