"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sanitizeMessage = exports.isSolidityType = void 0;
// Reference: https://github.com/MetaMask/metamask-extension/blob/59cee4e7bed935fb2cc188f94ce6c78adee18904/ui/helpers/utils/util.js#L453
const solidityTypes = () => {
    const types = [
        "bool",
        "address",
        "string",
        "bytes",
        "int",
        "uint",
        "fixed",
        "ufixed",
    ];
    const ints = Array.from(new Array(32)).map((_, index) => `int${(index + 1) * 8}`);
    const uints = Array.from(new Array(32)).map((_, index) => `uint${(index + 1) * 8}`);
    const bytes = Array.from(new Array(32)).map((_, index) => `bytes${index + 1}`);
    /**
     * fixed and ufixed
     * This value type also can be declared keywords such as ufixedMxN and fixedMxN.
     * The M represents the amount of bits that the type takes,
     * with N representing the number of decimal points that are available.
     *  M has to be divisible by 8, and a number from 8 to 256.
     * N has to be a value between 0 and 80, also being inclusive.
     */
    const fixedM = Array.from(new Array(32)).map((_, index) => `fixed${(index + 1) * 8}`);
    const ufixedM = Array.from(new Array(32)).map((_, index) => `ufixed${(index + 1) * 8}`);
    const fixed = Array.from(new Array(80)).map((_, index) => fixedM.map((aFixedM) => `${aFixedM}x${index + 1}`));
    const ufixed = Array.from(new Array(80)).map((_, index) => ufixedM.map((auFixedM) => `${auFixedM}x${index + 1}`));
    return [
        ...types,
        ...ints,
        ...uints,
        ...bytes,
        ...fixed.flat(),
        ...ufixed.flat(),
    ];
};
const SOLIDITY_TYPES = solidityTypes();
const isSolidityType = (potentialType) => SOLIDITY_TYPES.includes(potentialType);
exports.isSolidityType = isSolidityType;
const isArrayType = (potentialArrayType) => potentialArrayType.match(/\[[[0-9]*\]*/u) !== null;
const stripArrayType = (potentialArrayType) => potentialArrayType.replace(/\[[[0-9]*\]*/gu, "");
const stripOneLayerOfNesting = (potentialArrayType) => potentialArrayType.replace(/\[(\d*)\]/u, "");
const sanitizeMessage = (msg, primaryType, types) => {
    if (!types) {
        throw new Error(`Invalid types definition`);
    }
    if (!primaryType) {
        throw new Error(`Invalid primary type definition`);
    }
    // Primary type can be an array.
    const isArray = primaryType && isArrayType(primaryType);
    if (isArray) {
        msg = msg;
        return {
            value: msg.map((value) => (0, exports.sanitizeMessage)(value, stripOneLayerOfNesting(primaryType), types)),
            type: primaryType,
        };
    }
    else if ((0, exports.isSolidityType)(primaryType)) {
        return { value: msg, type: primaryType };
    }
    // If not, assume to be struct
    const baseType = isArray ? stripArrayType(primaryType) : primaryType;
    const baseTypeDefinitions = types[baseType];
    if (!baseTypeDefinitions) {
        throw new Error(`Invalid primary type definition`);
    }
    const msgKeys = Object.keys(msg);
    const sanitizedStruct = {};
    msgKeys.forEach((msgKey) => {
        const definedType = Object.values(baseTypeDefinitions).find((baseTypeDefinition) => baseTypeDefinition.name === msgKey);
        if (!definedType) {
            return;
        }
        sanitizedStruct[msgKey] = (0, exports.sanitizeMessage)(msg[msgKey], definedType.type, types);
    });
    return { type: primaryType, value: sanitizedStruct };
};
exports.sanitizeMessage = sanitizeMessage;
