"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLastUsedWebauthnKey = exports.fetchKeysFromDb = exports.storeKeysToDb = exports.SESSION_KEY_TYPES = void 0;
const indexDBWrapper_1 = require("./indexDBWrapper");
const logger_1 = require("./logger");
var SESSION_KEY_TYPES;
(function (SESSION_KEY_TYPES) {
    SESSION_KEY_TYPES["WEBAUTHN"] = "WEBAUTHN";
    SESSION_KEY_TYPES["CRYPTO"] = "CRYPTO";
})(SESSION_KEY_TYPES || (exports.SESSION_KEY_TYPES = SESSION_KEY_TYPES = {}));
var VERSION;
(function (VERSION) {
    // V1 format has a data field with sessionId and sessionKeys list.
    VERSION[VERSION["v1"] = 1] = "v1";
})(VERSION || (VERSION = {}));
const SESSION_DB_NAME = "session-keys-db";
const SESSION_DB_VERSION = 2;
const SESSION_DB_STORE_NAME = "sessionKeysV2";
const SESSION_DB_KEY_NAME = "sessionKeyIdentifier";
const generatePrimaryKey = (keyPrefix, parsedUser) => {
    if (!keyPrefix) {
        throw new Error("keyPrefix is required");
    }
    // Create a normalized primary key
    return `${keyPrefix}-${parsedUser.serializedUser}`;
};
const storeKeysToDb = async ({ keyPrefix, sessionId, parsedUser, sessionKeys, }) => {
    let db = await indexDBWrapper_1.indexDBWrapperInstance.getInstance(SESSION_DB_NAME, SESSION_DB_VERSION, SESSION_DB_STORE_NAME, SESSION_DB_KEY_NAME);
    await db.open(SESSION_DB_NAME, SESSION_DB_VERSION, SESSION_DB_STORE_NAME, SESSION_DB_KEY_NAME);
    let primaryKey = generatePrimaryKey(keyPrefix, parsedUser);
    if (!sessionId) {
        throw new Error("Session ID is required.");
    }
    try {
        sessionKeys.forEach((key) => {
            if (!isValidSessionKey(key)) {
                throw new Error("Invalid session key.");
            }
        });
        await db.add({
            [SESSION_DB_KEY_NAME]: primaryKey,
            version: VERSION.v1,
            data: { sessionId, sessionKeys: sessionKeys },
        });
    }
    finally {
        await db.close();
    }
};
exports.storeKeysToDb = storeKeysToDb;
const fetchKeysFromDb = async ({ keyPrefix, parsedUser, sessionId, keyType, }) => {
    var _a;
    if (!sessionId) {
        throw new Error("Session ID is required.");
    }
    let db = await indexDBWrapper_1.indexDBWrapperInstance.getInstance(SESSION_DB_NAME, SESSION_DB_VERSION, SESSION_DB_STORE_NAME, SESSION_DB_KEY_NAME);
    await db.open(SESSION_DB_NAME, SESSION_DB_VERSION, SESSION_DB_STORE_NAME, SESSION_DB_KEY_NAME);
    try {
        const primaryKey = generatePrimaryKey(keyPrefix, parsedUser);
        const storedSession = (_a = (await db.get(primaryKey, SESSION_DB_KEY_NAME))) === null || _a === void 0 ? void 0 : _a.data;
        // Check if the session Id matches to avoid using stale keys
        if (storedSession.sessionId !== sessionId) {
            throw new Error("Session ID mismatch.");
        }
        if (!storedSession.sessionKeys) {
            throw new Error("No keys found.");
        }
        // Filter keys based on keyType
        const filteredSessionKeys = storedSession.sessionKeys.filter((key) => key.keyType === keyType && isValidSessionKey(key));
        return filteredSessionKeys[0] ? filteredSessionKeys[0] : null;
    }
    finally {
        await db.close();
    }
};
exports.fetchKeysFromDb = fetchKeysFromDb;
const getLastUsedWebauthnKey = async ({ keyPrefix, parsedUser, }) => {
    if (!keyPrefix) {
        throw new Error("keyPrefix is required.");
    }
    if (!parsedUser) {
        throw new Error("User is required.");
    }
    let db = await indexDBWrapper_1.indexDBWrapperInstance.getInstance(SESSION_DB_NAME, SESSION_DB_VERSION, SESSION_DB_STORE_NAME, SESSION_DB_KEY_NAME);
    await db.open(SESSION_DB_NAME, SESSION_DB_VERSION, SESSION_DB_STORE_NAME, SESSION_DB_KEY_NAME);
    try {
        const primaryKey = generatePrimaryKey(keyPrefix, parsedUser);
        const dbData = await db.get(primaryKey, SESSION_DB_KEY_NAME);
        const data = dbData === null || dbData === void 0 ? void 0 : dbData.data;
        const sessionKeys = data.sessionKeys;
        const webauthnKey = sessionKeys.find((key) => key.keyType === SESSION_KEY_TYPES.WEBAUTHN && isValidSessionKey(key));
        if (!webauthnKey) {
            return undefined;
        }
        return webauthnKey;
    }
    catch (error) {
        logger_1.logger.error(`An error occurred while fetching last used webauthn key: ${error}`);
        return undefined;
    }
    finally {
        await db.close();
    }
};
exports.getLastUsedWebauthnKey = getLastUsedWebauthnKey;
const isValidSessionKey = (sessionKey) => {
    if (!sessionKey.keyType) {
        logger_1.logger.error("Key type is required.");
        return false;
    }
    if (sessionKey.keyType === SESSION_KEY_TYPES.CRYPTO) {
        if (!sessionKey.publicKey) {
            logger_1.logger.error("Public key is required.");
            return false;
        }
        if (!sessionKey.privateKey) {
            logger_1.logger.error("Private key is required.");
            return false;
        }
        return true;
    }
    if (sessionKey.keyType === SESSION_KEY_TYPES.WEBAUTHN) {
        if (!sessionKey.credentialId) {
            logger_1.logger.error("Credential ID is required.");
            return false;
        }
        if (!sessionKey.webAuthnRegistrationCredential) {
            logger_1.logger.error("Webauthn registration credential is required.");
            return false;
        }
        return true;
    }
    // @ts-ignore
    logger_1.logger.error(`Invalid key type: ${sessionKey.keyType}`);
    return false;
};
