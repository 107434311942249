"use strict";
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _UserApprovalStorage_instances, _UserApprovalStorage_removeOldLSKey, _UserApprovalStorage_sanitizeUserApprovals, _UserApprovalStorage_updateUserApprovals, _UserApprovalStorage_findApprovalIndex, _UserApprovalStorage_saveOrUpdateUserApproval;
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserApprovalStorage = exports.storageWrapper = void 0;
const frontend_utils_1 = require("frontend-utils");
Object.defineProperty(exports, "storageWrapper", { enumerable: true, get: function () { return frontend_utils_1.storage; } });
const constants_1 = require("../constants");
const USER_APPROVAL_TTL_MILLIS = 60 * 60 * 24 * 30 * 1000; // 30 days
class UserApprovalStorage extends frontend_utils_1.StorageWrapper {
    constructor() {
        super(...arguments);
        _UserApprovalStorage_instances.add(this);
    }
    getStorage() {
        return this._storage;
    }
    getSanitizedUserApprovals() {
        const userApprovalsArr = JSON.parse(this._storage.getItem(constants_1.LS_USER_APPROVALS_KEY) || "[]");
        const sanitizedUserApprovalsArr = __classPrivateFieldGet(this, _UserApprovalStorage_instances, "m", _UserApprovalStorage_sanitizeUserApprovals).call(this, userApprovalsArr);
        __classPrivateFieldGet(this, _UserApprovalStorage_instances, "m", _UserApprovalStorage_updateUserApprovals).call(this, sanitizedUserApprovalsArr);
        return sanitizedUserApprovalsArr;
    }
    getUserApprovalsFromStorage() {
        try {
            const userApprovalsFromLS = this.getSanitizedUserApprovals();
            return Array.isArray(userApprovalsFromLS) ? [...userApprovalsFromLS] : [];
        }
        catch (e) {
            return [];
        }
    }
    saveToUserApprovalStorage(currentDomain, parsedUser, appId) {
        const existingApprovals = this.getUserApprovalsFromStorage();
        __classPrivateFieldGet(this, _UserApprovalStorage_instances, "m", _UserApprovalStorage_saveOrUpdateUserApproval).call(this, existingApprovals, parsedUser, appId, currentDomain);
        __classPrivateFieldGet(this, _UserApprovalStorage_instances, "m", _UserApprovalStorage_updateUserApprovals).call(this, existingApprovals);
    }
    getUserApprovalsForUser(parsedUser, appId) {
        __classPrivateFieldGet(this, _UserApprovalStorage_instances, "m", _UserApprovalStorage_removeOldLSKey).call(this);
        const userApprovalsArr = this.getUserApprovalsFromStorage();
        const currentApprovalIdx = __classPrivateFieldGet(this, _UserApprovalStorage_instances, "m", _UserApprovalStorage_findApprovalIndex).call(this, userApprovalsArr, parsedUser, appId);
        if (currentApprovalIdx === -1)
            return [];
        const { domains = [] } = userApprovalsArr[currentApprovalIdx];
        return domains;
    }
}
exports.UserApprovalStorage = UserApprovalStorage;
_UserApprovalStorage_instances = new WeakSet(), _UserApprovalStorage_removeOldLSKey = function _UserApprovalStorage_removeOldLSKey() {
    const oldLocalStorageKey = "user_approved_requester_domains";
    if (this._storage.hasOwnProperty(oldLocalStorageKey)) {
        this._storage.removeItem(oldLocalStorageKey);
    }
}, _UserApprovalStorage_sanitizeUserApprovals = function _UserApprovalStorage_sanitizeUserApprovals(userApprovalsArr) {
    return userApprovalsArr.map((d) => {
        const sanitizedUserApprovals = d.domains.filter((d) => d.expires_at_millis > Date.now());
        return {
            user_id: d.user_id,
            appId: d.appId,
            domains: sanitizedUserApprovals,
        };
    });
}, _UserApprovalStorage_updateUserApprovals = function _UserApprovalStorage_updateUserApprovals(userApprovalsArr) {
    this._storage.setItem(constants_1.LS_USER_APPROVALS_KEY, JSON.stringify(userApprovalsArr));
}, _UserApprovalStorage_findApprovalIndex = function _UserApprovalStorage_findApprovalIndex(userApprovalsArr, parsedUser, appId) {
    return userApprovalsArr.findIndex((d) => d.user_id === parsedUser.serializedUser && d.appId === appId);
}, _UserApprovalStorage_saveOrUpdateUserApproval = function _UserApprovalStorage_saveOrUpdateUserApproval(userApprovalsArr, parsedUser, appId, currentDomain) {
    const currentApprovalIdx = __classPrivateFieldGet(this, _UserApprovalStorage_instances, "m", _UserApprovalStorage_findApprovalIndex).call(this, userApprovalsArr, parsedUser, appId);
    const createNewApproval = () => userApprovalsArr.push({
        user_id: parsedUser.serializedUser,
        appId,
        domains: [
            {
                domain: currentDomain,
                expires_at_millis: Date.now() + USER_APPROVAL_TTL_MILLIS,
            },
        ],
    });
    const updateDomainInApproval = () => {
        const { domains = [] } = userApprovalsArr[currentApprovalIdx];
        const domainIndex = domains.findIndex((d) => d.domain === currentDomain);
        if (domainIndex === -1) {
            domains.push({
                domain: currentDomain,
                expires_at_millis: Date.now() + USER_APPROVAL_TTL_MILLIS,
            });
        }
    };
    const noCurrentApproval = currentApprovalIdx === -1;
    if (noCurrentApproval) {
        createNewApproval();
    }
    else {
        updateDomainInApproval();
    }
};
