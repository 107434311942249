import { useCallback, useEffect, lazy } from "react";
import { getAppDetails } from "../../api";
import { getCurrentSession, postMessage } from "../../utils";
import { SUCCESS, FRAME_READY } from "../../constants";
import { logger } from "frontend-utils/logger";
import { useStore } from "../../store";
import { VisibilityTracker } from "../../utils/visibiltyTracker";

const LazyPreload = (
  importStatement: () => Promise<{ default: React.ComponentType<any> }>
) => {
  const Component = lazy(importStatement);
  // @ts-ignore
  Component.preload = importStatement;
  return Component;
};
const ApprovalFramePreload = LazyPreload(() => import("../ApprovalFrame"));
const ConsentFramePreload = LazyPreload(() => import("../ConsentFrame"));
const LoginFramePreload = LazyPreload(() => import("../Login"));

interface PrefetchFrameProps {
  visibilityTracker: VisibilityTracker | null;
  isWidget?: boolean;
}

export default function PrefetchFrame({
  isWidget = false,
}: PrefetchFrameProps) {
  const appId = useStore((state) => state.appId);

  const fetchAppDataAndRefreshSession = useCallback(async () => {
    if (!appId) return;
    const itemsTobePrefetched = [
      // @ts-ignore
      ApprovalFramePreload.preload(), // preload approval frame assets (css, js)
      // @ts-ignore
      ConsentFramePreload.preload(), // preload consent frame assets (css, js)
      // @ts-ignore
      LoginFramePreload.preload(), // preload login frame assets (css, js)
      getAppDetails(appId, true),
      getCurrentSession(), // this will refresh token for existing user if present
    ];
    try {
      await Promise.allSettled(itemsTobePrefetched);
      postMessage(SUCCESS, {}, true);
    } catch (err) {
      logger.log("Failed to prefetch", err);
      postMessage(SUCCESS, {}, true);
    }
  }, [appId]);

  useEffect(() => {
    // under widget this is handled by widget it self
    if (isWidget) return;
    postMessage(FRAME_READY, { isFrameVisible: false });
  }, [isWidget]);

  useEffect(() => {
    fetchAppDataAndRefreshSession();
  }, [fetchAppDataAndRefreshSession]);

  return null;
}
