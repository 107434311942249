"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.decrypt = exports.getAppDetails = exports.getWallet = exports.signTypedData = exports.signTransactionMultiple = exports.signTransaction = exports.signMessage = exports.grantConsent = exports.getConsentData = exports.cachedConsentData = void 0;
const store_1 = require("../store");
const frontend_utils_1 = require("frontend-utils");
const baseApi_1 = require("./baseApi");
const constants_1 = require("../constants");
const utils_1 = require("../utils");
const consentDataCacheMap = new Map();
const cachedConsentData = async (consentToken) => {
    const cachedData = consentDataCacheMap.get(consentToken);
    if (cachedData) {
        return cachedData;
    }
    const consentData = await (0, exports.getConsentData)(consentToken);
    consentDataCacheMap.set(consentToken, consentData);
    return consentData;
};
exports.cachedConsentData = cachedConsentData;
const getConsentData = async (consentToken) => {
    return (0, baseApi_1.baseApi)({
        apiPath: baseApi_1.API_PATHS.GET_CONSENT_DATA,
        requestBody: { consentToken },
    });
};
exports.getConsentData = getConsentData;
const grantConsent = async (consentToken) => await (0, baseApi_1.baseApi)({
    apiPath: baseApi_1.API_PATHS.GRANT_CONSENT,
    requestBody: { consentToken },
});
exports.grantConsent = grantConsent;
const signMessage = async () => {
    const { payload: message, reason } = store_1.useStore.getState().approvalFrameSlice;
    const body = {
        message,
        reason,
        appId: store_1.useStore.getState().appId,
    };
    return await (0, baseApi_1.baseApi)({ apiPath: baseApi_1.API_PATHS.SIGN_MESSAGE, requestBody: body });
};
exports.signMessage = signMessage;
const signTransaction = async () => {
    const { payload: transactionObject, reason } = store_1.useStore.getState().approvalFrameSlice;
    const body = {
        transactionObject,
        reason,
        appId: store_1.useStore.getState().appId,
    };
    return (0, baseApi_1.baseApi)({ apiPath: baseApi_1.API_PATHS.SIGN_TRANSACTION, requestBody: body });
};
exports.signTransaction = signTransaction;
const signTransactionMultiple = async () => {
    const { payload: transactions, reason } = store_1.useStore.getState().approvalFrameSlice;
    const body = {
        transactions,
        reason,
        appId: store_1.useStore.getState().appId,
    };
    return (0, baseApi_1.baseApi)({
        apiPath: baseApi_1.API_PATHS.SIGN_TRANSACTION_MULTIPLE,
        requestBody: body,
    });
};
exports.signTransactionMultiple = signTransactionMultiple;
const signTypedData = async () => {
    const { payload: typedData, reason } = store_1.useStore.getState().approvalFrameSlice;
    const body = {
        data: typedData,
        reason,
        appId: store_1.useStore.getState().appId,
    };
    return (0, baseApi_1.baseApi)({ apiPath: baseApi_1.API_PATHS.SIGN_TYPED_DATA, requestBody: body });
};
exports.signTypedData = signTypedData;
const getWallet = async (user) => {
    return await (0, baseApi_1.baseApi)({
        apiPath: baseApi_1.API_PATHS.GET_WALLET,
        requestBody: { user },
    });
};
exports.getWallet = getWallet;
const fetchPublicAppData = async (appId) => {
    const appData = await (0, baseApi_1.baseApi)({
        apiPath: baseApi_1.API_PATHS.GET_APP_DETAILS,
        requestBody: { appId },
    });
    // set appdata in localStorage
    (0, frontend_utils_1.setCachedAppData)(appId, appData);
    return appData;
};
// handles case where fetching app data is non blocking
// once the data loading is done then this callback is run
// this way we can always fetch and set the latest app data without blocking ui
const lazyAppDataCallback = (data) => {
    const { toggleApprovalBanner } = store_1.useStore.getState();
    if (constants_1.WALLET_APPROVAL_CHECK_ENABLED) {
        const { originApprovalStatus = "", appIdApprovalStatus = "" } = data || {};
        toggleApprovalBanner(originApprovalStatus !== constants_1.APPROVAL_STATUS_MAP.APPROVED ||
            appIdApprovalStatus !== constants_1.APPROVAL_STATUS_MAP.APPROVED);
    }
    return data;
};
const getAppDetails = async (appId, bypassCache = false) => {
    if (!appId)
        return {};
    // If bypassCache isn't true, we will try to read the data from the cache.
    // If cached data is available, we will return that and fetch the latest in the background.
    // If the cached data isn't available, we will get the latest data.
    const cachedAppData = bypassCache ? null : (0, frontend_utils_1.getCachedAppDataByAppId)(appId);
    const appData = cachedAppData
        ? fetchPublicAppData(appId)
            .then(lazyAppDataCallback)
            .catch((err) => {
            (0, utils_1.handleErrorState)(err);
        })
        : await fetchPublicAppData(appId);
    return cachedAppData || appData;
};
exports.getAppDetails = getAppDetails;
const decrypt = async () => {
    const { payload: { encryptedData, endToEndEncryption }, description, } = store_1.useStore.getState().approvalFrameSlice;
    const body = {
        encryptedData,
        endToEndEncryption,
        description,
        appId: store_1.useStore.getState().appId,
    };
    return await (0, baseApi_1.baseApi)({
        apiPath: baseApi_1.API_PATHS.DECRYPT,
        requestBody: body,
    });
};
exports.decrypt = decrypt;
