import { lazy, useCallback, useEffect } from "react";
import { postMessage } from "../../utils";
import { MESSAGE, SDK_MESSAGE, FRAME_READY } from "../../constants";
import { useStore } from "../../store";
import { shallow } from "zustand/shallow";
import { VisibilityTracker } from "../../utils/visibiltyTracker";
import React from "react";
import { WIDGET_TYPE } from "../../store/widgetSlice";

const CHILD_COMPONENTS = {
  [WIDGET_TYPE.LOGIN]: lazy(() => import("../Login")),
  // SIGN was renamed to APPROVAL. It's here for backward compatibility.
  [WIDGET_TYPE.SIGN]: lazy(() => import("../ApprovalFrame")),
  [WIDGET_TYPE.APPROVAL]: lazy(() => import("../ApprovalFrame")),
  [WIDGET_TYPE.CONSENT]: lazy(() => import("../ConsentFrame")),
  [WIDGET_TYPE.PREFETCH]: lazy(() => import("../PrefetchFrame")),
};

interface WidgetProps {
  visibilityTracker: VisibilityTracker | null;
}

export const Widget = ({ visibilityTracker }: WidgetProps) => {
  const widgetType = useStore((state) => state.widgetSlice.widgetType, shallow);
  const initializeFrame = useStore(
    useCallback((state) => state.initializeFrame, [])
  );
  const setUsePasskey = useStore((state) => state.setUsePasskey, shallow);

  const handleMessage = useCallback(
    async (event: MessageEvent) => {
      const { data = {}, source } = event;
      const { eventType, payload = {} } = data;
      const { internalOptions = {} } = payload;
      const { usePasskey = false } = internalOptions;

      if (source !== window.parent || eventType !== SDK_MESSAGE) return;
      setUsePasskey(usePasskey);
      initializeFrame(payload);
    },
    [initializeFrame, setUsePasskey]
  );

  useEffect(() => {
    window.addEventListener(MESSAGE, handleMessage);
    postMessage(FRAME_READY);
    return () => {
      window.removeEventListener(MESSAGE, handleMessage);
    };
  }, [handleMessage]);

  if (!widgetType) return null;

  const Component = CHILD_COMPONENTS[widgetType];
  return <Component visibilityTracker={visibilityTracker} isWidget />;
};
