"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useImage = exports.PLACEHOLDER_IMAGE_URL = void 0;
const react_1 = require("react");
/**
 * Placeholder URL for images when loading fails.
 */
exports.PLACEHOLDER_IMAGE_URL = "/images/nft-placeholder.svg";
/**
 * Custom hook to handle image loading and error events.
 * @param imgRef Reference to the image element.
 * @returns Object containing image event handlers and loading state.
 */
const useImage = (imgRef) => {
    const [isImageLoading, setIsImageLoading] = (0, react_1.useState)(true);
    /**
     * Callback function invoked when the image is successfully loaded.
     * Adjusts the maximum height of the image if it's taller than it is wide.
     * Updates loading state to false.
     */
    const onImageLoad = () => {
        try {
            if (imgRef.current &&
                imgRef.current.naturalHeight > imgRef.current.naturalWidth)
                imgRef.current.style.maxHeight = "100%";
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setIsImageLoading(false);
        }
    };
    /**
     * Callback function invoked when the image fails to load.
     * Replaces the image source with a placeholder URL.
     * Updates loading state to false.
     */
    const onImageError = () => {
        if (!imgRef.current)
            return;
        imgRef.current.src = exports.PLACEHOLDER_IMAGE_URL;
        setIsImageLoading(false);
    };
    return { onImageError, onImageLoad, isImageLoading };
};
exports.useImage = useImage;
